import styled from 'styled-components';
import {useWebinars} from 'hooks/useWebinars';
import {AddWebinarModal} from 'components/Modals/AddWebinarModal/AddWebinarModal';
import {useModal} from 'hooks/useModal';
import {WebinarItem} from 'pages/WebinarsList/WebinarItem/WebinarItem';
import {ActionButton} from 'components/ActionButton/ActionButton';
import AddIcon from '@mui/icons-material/Add';

export const WebinarsList = () => {
    const {data: webinars} = useWebinars()

    const [isAddingOpen, openAddingModal, closeAddingModal] = useModal()

    const webinarsItems = webinars
        ? webinars.map(webinar => <WebinarItem key={webinar._id} webinar={webinar}/>)
        : []

    return (
        <WebinarsListBlock>
            <AddWebinarModal isOpen={isAddingOpen} onClickClose={closeAddingModal}/>
            <HeadBlock>
                <MenuTitle>Доступные вебинары</MenuTitle>
                <ActionButton onClick={openAddingModal} Icon={AddIcon}
                              fontSize={'large'}/>
            </HeadBlock>
            <TableBlock>
                {webinars?.length
                    ? <Table>
                        <TableHead>
                            <tr>
                                <td>TOPIC</td>
                                <td>START DATE</td>
                                <td>ACTION</td>
                            </tr>
                        </TableHead>
                        <tbody>{webinarsItems}</tbody>
                    </Table>
                    : <InfoText>Вебинаров пока нет</InfoText>}
            </TableBlock>
        </WebinarsListBlock>
    )
}

const TableHead = styled.thead`
  font-size: 24px;
  height: 46px;

  & th {
    font-weight: normal;
  }
`

const WebinarsListBlock = styled.div`
  width: 100%;
`

const HeadBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const TableBlock = styled.div`
  display: flex;
  justify-content: center;
`

const MenuTitle = styled.div`
  margin: 10px;
  font-size: 30px;
  text-align: center;
`

const Table = styled.table`
  margin: 10px;
  padding: 10px;
  max-width: 100%;
  border-radius: 7px;
  font-size: 22px;
  text-align: center;
  background: linear-gradient(to bottom right, #f2f7fc, #ddf0fd);
  box-shadow: 0 3px 7px 1px #99cffc;
  border-collapse: collapse;
  width: 100%;

  & th, td {
    border: solid 1px #99cffc;
    padding: 10px;

    @media (max-width: 814px) {
      font-size: 16px;
    }
  }

  @media (max-width: 814px) {
    font-size: 16px;
  }
`

const InfoText = styled.div`
  width: 100%;
  text-align: center;
`
