import {Modal} from 'components/Modals/Modal/Modal';
import {ButtonsBlock} from 'components/EditingForm/EditingForm';
import {CustomButton} from 'components/CustomButton/CustomButton';
import {TEXT_CONSTANTS} from 'enums/textConstants';
import {ParticipantType} from 'api/webinars-api';
import {Participant} from 'components/Participant/Participant';
import styled from 'styled-components';

type PropsType = {
    isOpen: boolean
    onClickClose: () => void
    participants: ParticipantType[]
    webinarTopic: string
    startDate: string
}

export const ParticipantsModal = (props: PropsType) => {
    const {isOpen, onClickClose, participants, webinarTopic, startDate} = props

    const participantsList = participants.map((participant, index) => (
        <Participant key={participant.email + index}
                     index={index + 1}
                     participant={participant}/>
    ))

    return (
        <Modal isOpen={isOpen} onClickClose={onClickClose}
               title={TEXT_CONSTANTS.PARTICIPANTS_MODAL_TEXT}>
            <WebinarInfo>
                {webinarTopic} {startDate}, {participants.length} участников
            </WebinarInfo>
            <ParticipantsBlock>
                {participants.length ? participantsList : 'Участников пока нет'}
            </ParticipantsBlock>
            <ButtonsBlock>
                <CustomButton name={'CANCEL'} onClick={onClickClose}/>
            </ButtonsBlock>
        </Modal>
    )
}

const WebinarInfo = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: bold;
  margin-bottom: 10px;
`

const ParticipantsBlock = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-right: 5px;
  max-height: 500px;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: #E6E6E6;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #B3B3B3;
    border-radius: 5px;
  }
`
