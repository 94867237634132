import {memo} from 'react';
import {Button, SvgIconTypeMap} from '@mui/material';
import {OverridableComponent} from '@mui/material/OverridableComponent';

type PropsType = {
    onClick: () => void
    Icon: OverridableComponent<SvgIconTypeMap> & { muiName: string }
    fontSize?: 'small' | 'inherit' | 'large' | 'medium'
}

export const ActionButton = memo((props: PropsType) => {
    const {onClick, Icon, fontSize = 'small'} = props

    return (
        <Button onClick={onClick} color={'primary'}>
            <Icon fontSize={fontSize}/>
        </Button>
    )
})
