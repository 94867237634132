import { ApiSettings } from './api.settings'

export type EnvironmentVariable = Record<string, string | undefined>

export enum EnvironmentsTypes {
  DEVELOPMENT = 'DEVELOPMENT',
  PRODUCTION = 'PRODUCTION',
  STAGING = 'STAGING',
}

export class EnvironmentSettings {
  constructor(private env: EnvironmentsTypes) {}

  get getEnv() {
    return this.env
  }

  get isDevelopment() {
    return this.env === 'DEVELOPMENT'
  }

  get isProduction() {
    return this.env === 'PRODUCTION'
  }

  get isStaging() {
    return this.env === 'STAGING'
  }
}

class AppSettings {
  constructor(public env: EnvironmentSettings, public api: ApiSettings) {}
}

const env = new EnvironmentSettings(process.env.REACT_APP_ENV as EnvironmentsTypes)

export const api = new ApiSettings(process.env)

export const appSettings = new AppSettings(env, api)
