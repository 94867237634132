import {useState} from 'react';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import {FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput} from '@mui/material';
import {UseFormRegister} from 'react-hook-form';
import {FieldsType} from 'pages/Login/Login';

type PropsType = {
    register: UseFormRegister<FieldsType>
}

export const PasswordFormControl = ({register}: PropsType) => {
    const [isShow, setIsShow] = useState<boolean>(false)

    const handleClickShowValue = () => setIsShow(state => !state)

    return (
        <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor={`outlined-adornment-password`}>password</InputLabel>
            <OutlinedInput {...register('password')}
                           id={`outlined-adornment-password`}
                           type={isShow ? 'text' : 'password'}
                           label={'password'}
                           required
                           endAdornment={
                    <InputAdornment position="end">
                        <IconButton aria-label={`toggle password visibility`}
                                    onClick={handleClickShowValue}
                                    edge="end">
                            {isShow ? <VisibilityOff/> : <Visibility/>}
                        </IconButton>
                    </InputAdornment>}/>
        </FormControl>
    )
}
