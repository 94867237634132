import {TextField} from '@mui/material';
import styled from 'styled-components';
import {UseFormRegister} from 'react-hook-form';
import {FormFieldsType} from 'components/EditingForm/EditingForm';

type PropsType = {
    name: keyof FormFieldsType
    register: UseFormRegister<FormFieldsType>
}

export const FormField = (props: PropsType) => {
    const {name, register} = props

    return (
        <FieldBlock>
            <TextField autoFocus {...register(name)} fullWidth label={name} required/>
        </FieldBlock>
    )
}

export const FieldBlock = styled.div`
  margin-bottom: 20px;
`
