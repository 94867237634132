import {useMutation, UseMutationResult} from 'react-query';
import {SuccessResponseType, webinarsAPI, WebinarType} from 'api/webinars-api';
import {queryClient} from 'index';
import {QUERY_KEYS} from 'enums/queryKeys';

export const useDeleteWebinar = (webinarId: string): UseMutationResult<SuccessResponseType, unknown, string> =>
    useMutation((id: string) => webinarsAPI.deleteWebinar(id), {
        onSuccess: () => {
            const accounts = queryClient.getQueryData<WebinarType[]>(QUERY_KEYS.WEBINARS_LIST);
            if (accounts) queryClient.setQueriesData(
                QUERY_KEYS.WEBINARS_LIST,
                accounts.filter((web) => web._id !== webinarId))
        },
        meta: {
            error: 'Ошибка при удалении вебинара'
        }
    })
