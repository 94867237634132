import {useForm} from 'react-hook-form';
import {WEBINAR_TOPICS} from 'enums/webinarTopics';
import styled from 'styled-components';
import {TopicSelect} from 'components/TopicSelect/TopicSelect';
import {FieldBlock, FormField} from 'components/FormField/FormField';
import {CustomButton} from 'components/CustomButton/CustomButton';
import {DateTimePicker} from 'components/DateTimePicker/DateTimePicker';

type PropsType = {
    defaultValues?: FormFieldsType
    onSubmit: (data: FormFieldsType) => void
    onClickClose: () => void
}

export const EditingForm = (props: PropsType) => {
    const {
        defaultValues = {topic: WEBINAR_TOPICS.HTML_CSS, regLink: '', startDate: ''},
        onSubmit,
        onClickClose,
    } = props

    const {register, handleSubmit, setValue, watch} = useForm({defaultValues})

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormField name={'regLink'} register={register}/>
            <DateTimePicker name={'startDate'} setValue={setValue} value={watch('startDate')}/>
            <FieldBlock>
                <TopicSelect defaultValue={defaultValues.topic} register={register}/>
            </FieldBlock>
            <ButtonsBlock>
                <CustomButton name={'CANCEL'} onClick={onClickClose} color={'error'}/>
                <CustomButton name={'SAVE'} type={'submit'}/>
            </ButtonsBlock>
        </form>
    )
}

export const ButtonsBlock = styled.div`
  display: flex;
  justify-content: center;

  & Button {
    width: 120px;
    margin: 20px 10px 0 10px;
  }
`

//types
export type FormFieldsType = {
    topic: WEBINAR_TOPICS
    regLink: string
    startDate: string
}
