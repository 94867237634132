import axios from 'axios';
import {loadValue} from 'utils/localstorage';
import {LOCALSTORAGE_KEYS} from 'enums/localstorageKeys';
import {appSettings} from '../settings/app-settings';

export const instance = axios.create({
    baseURL: appSettings.api.REACT_APP_WEBINARS_SERVICE_URL,
})

instance.interceptors.request.use((request) => {
    const token = loadValue(LOCALSTORAGE_KEYS.ACCESS_TOKEN) || ''

    request.headers.Authorization = `Bearer ${token}`

    return request
})
