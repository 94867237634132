import {FormControl, InputLabel, MenuItem, Select} from '@mui/material';
import {UseFormRegister} from 'react-hook-form';
import {WEBINAR_TOPICS} from 'enums/webinarTopics';
import {FormFieldsType} from 'components/EditingForm/EditingForm';

type PropsType = {
    defaultValue: WEBINAR_TOPICS
    register: UseFormRegister<FormFieldsType>
}

export const TopicSelect = (props: PropsType) => {
    const {defaultValue, register} = props

    const options = [
        WEBINAR_TOPICS.HTML_CSS,
        WEBINAR_TOPICS.JAVASCRIPT,
        WEBINAR_TOPICS.REACT,
        WEBINAR_TOPICS.BACKEND]

    return (
        <FormControl fullWidth>
            <InputLabel id="select">topic</InputLabel>
            <Select labelId="select"
                    id="select"
                    label="topic"
                    defaultValue={defaultValue}
                    {...register('topic')}>
                {options.map((opt, i) => (
                    <MenuItem value={opt} key={i}>{WEBINAR_TOPICS[opt]}</MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
