import {memo, ReactNode} from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import {ActionButton} from 'components/ActionButton/ActionButton';

type PropsType = {
    onClickClose: () => void
    isOpen: boolean
    title: string
    children: ReactNode
}

export const Modal = memo((props: PropsType) => {
    const {onClickClose, isOpen, children, title} = props

    if (!isOpen) return null

    return ReactDOM.createPortal(
        <>
            <Background onClick={onClickClose}/>
            <ModalWindow>
                <EscapeIcon>
                    <ActionButton onClick={onClickClose} Icon={CloseIcon}/>
                </EscapeIcon>
                <Title>{title}</Title>
                {children}
            </ModalWindow>
        </>, document.body)
})

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.3;
  z-index: 1;
`

const ModalWindow = styled.div`
  position: fixed;
  top: 40%;
  left: 50%;
  min-width: 320px;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
  padding: 30px 16px;
  text-align: center;
  border-radius: 7px;
  background: linear-gradient(to bottom right, #e4f2ff, #c9e4f8);
  box-shadow: 0 27px 27px 0 rgba(0,0,0,0.2), 0 27px 27px 0 rgba(0,0,0,0.19);
`

const EscapeIcon = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  justify-content: flex-end;
`

export const Title = styled.div`
  margin-bottom: 16px;
  font-size: 26px;
`
