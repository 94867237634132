import {AxiosResponse} from 'axios';
import {instance} from 'api/instance';
import {deleteValue, saveValue} from 'utils/localstorage';
import {LOCALSTORAGE_KEYS} from 'enums/localstorageKeys';

export const authAPI = {
    register(data: LoginDataType) {
        return instance.post<AuthResponseType, AxiosResponse<AuthResponseType>, LoginDataType>('auth/register', data).then(res => res.data)
    },
    login(data: LoginDataType) {
        return instance.post<LoginResponseType, AxiosResponse<LoginResponseType>, LoginDataType>('auth/login', data).then(res => saveValue(LOCALSTORAGE_KEYS.ACCESS_TOKEN, res.data.access_token))
    },
    logout() {
        return deleteValue(LOCALSTORAGE_KEYS.ACCESS_TOKEN)
    },
}

//types
export type LoginDataType = {
    login: string,
    password: string
}

export type AuthResponseType = {
    _id: string
    createdAt: Date
    email: string
    passwordHash: string
    updatedAt: Date
    __v: number
}

export type LoginResponseType = {
    access_token: string
}
