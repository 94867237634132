import styled from 'styled-components';
import {WebinarType} from 'api/webinars-api';
import {EditWebinarModal} from 'components/Modals/EditWebinarModal/EditWebinarModal';
import {useModal} from 'hooks/useModal';
import {DeleteWebinarModal} from 'components/Modals/DeleteWebinarModal/DeleteWebinarModal';
import {SendFileModal} from 'components/Modals/SendFileModal/SendFileModal';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import {WEBINAR_TOPICS} from 'enums/webinarTopics';
import {TEXT_CONSTANTS} from 'enums/textConstants';
import {copyInBuffer} from 'utils/copyInBuffer';
import {ActionButton} from 'components/ActionButton/ActionButton';
import {ParticipantsModal} from 'components/Modals/ParticipantsModal/ParticipantsModal';
import {useCallback, useContext} from 'react';
import {SnackbarTextContext} from 'provider/SnackbarTextProvider';

type PropsType = {
    webinar: WebinarType
}

export const WebinarItem = ({webinar}: PropsType) => {
    const { setText } = useContext(SnackbarTextContext)

    const [isEditingOpen, openEditingModal, closeEditingModal] = useModal()
    const [isDeletingOpen, openDeletingModal, closeDeletingModal] = useModal()
    const [isFileSendingOpen, openFileSendingModal, closeFileSendingModal] = useModal()
    const [isParticipantsOpen, openParticipantsModal, closeParticipantsModal] = useModal()

    const copy = useCallback(() => {
        copyInBuffer(webinar.regLink, () => setText(TEXT_CONSTANTS.SUCCESS_COPY))
    }, [webinar.regLink])

    const formattedStartDate = new Date(webinar.startDate).toLocaleString()
    const webinarTopic = WEBINAR_TOPICS[webinar.topic]

    return (
        <>
            <EditWebinarModal isOpen={isEditingOpen}
                              onClickClose={closeEditingModal}
                              webinar={webinar}/>
            <DeleteWebinarModal isOpen={isDeletingOpen}
                                onClickClose={closeDeletingModal}
                                webinarId={webinar._id}/>
            <SendFileModal isOpen={isFileSendingOpen}
                           onClickClose={closeFileSendingModal}
                           webinarId={webinar._id}
                           turnOnSendSnackbar={() => setText(TEXT_CONSTANTS.SUCCESS_SEND)}/>
            <ParticipantsModal isOpen={isParticipantsOpen}
                               onClickClose={closeParticipantsModal}
                               participants={webinar.participants}
                               webinarTopic={webinarTopic}
                               startDate={formattedStartDate}/>
            <MenuItem>
                <td>{webinarTopic}</td>
                <td>{formattedStartDate}</td>
                <ActionButtonsBlock>
                    <ActionButton onClick={copy} Icon={ContentCopyIcon}/>
                    <ActionButton onClick={openEditingModal} Icon={EditIcon}/>
                    <ActionButton onClick={openDeletingModal} Icon={DeleteIcon}/>
                    <ActionButton onClick={openFileSendingModal} Icon={AttachEmailIcon}/>
                    <ActionButton onClick={openParticipantsModal} Icon={PeopleAltIcon}/>
                </ActionButtonsBlock>
            </MenuItem>
        </>
    )
}

const MenuItem = styled.tr`
  height: 50px;

  &:hover {
    background: linear-gradient(to bottom right, #dfeffd, #c3e4ff);
    box-shadow: 0 3px 7px 1px #75c0ff;
  }
`

const ActionButtonsBlock = styled.td`
  padding: 5px;
  
  & button {
    margin: 0;
  }
`
