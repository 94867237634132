import {createContext, ReactNode, useMemo, useState} from 'react';

interface ISnackbarTextContext {
    text: string
    setText: (text: string) => void
}

export const SnackbarTextContext = createContext({
    text: '',
    setText: (text: string) => {}
})

type PropsType = {
    children: ReactNode
}

export const SnackbarTextProvider = ({children}: PropsType) => {
    const [text, setText] = useState<string>('')

    const value: ISnackbarTextContext = useMemo(() => ({text, setText}), [text])

    return (
        <SnackbarTextContext.Provider value={value}>
            {children}
        </SnackbarTextContext.Provider>
    )
}
