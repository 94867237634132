import {AxiosResponse} from 'axios';
import {instance} from 'api/instance';
import {WEBINAR_TOPICS} from 'enums/webinarTopics';

export const webinarsAPI = {
    getAllWebinars() {
        return instance.get<WebinarType[]>('webinars/all').then(res => res.data)
    },
    getWebinarById(webinarId: string) {
        return instance.get<WebinarType>(`webinars/byWebinar/${webinarId}`).then(res => res.data)
    },
    createWebinar(newWebinar: CreateWebinarType) {
        return instance.post<WebinarType, AxiosResponse<WebinarType>, CreateWebinarType>('webinars', newWebinar).then(res => res.data)
    },
    addParticipant(webinarId: string, newParticipant: ParticipantType) {
        return instance.post<SuccessResponseType, AxiosResponse<SuccessResponseType>, ParticipantType>(`webinars/participant/${webinarId}`, newParticipant).then(res => res.data)
    },
    deleteWebinar(webinarId: string) {
        return instance.delete<SuccessResponseType>(`webinars/${webinarId}`).then(res => res.data)
    },
    updateWebinar(webinarToUpdate: WebinarType) {
        return instance.put<WebinarType, AxiosResponse<WebinarType>, WebinarType>(`webinars/${webinarToUpdate._id}`, webinarToUpdate).then(res => res.data)
    },
    getCSVFileOnEmail(webinarId: string) {
        return instance.get<SuccessResponseType>(`webinars/getFile/${webinarId}`)
    },
}

//types
export type CreateWebinarType = {
    regLink: string
    startDate: string
    topic: WEBINAR_TOPICS
}

export type WebinarType = {
    _id: string
    regLink: string
    startDate: string
    topic: WEBINAR_TOPICS
    participants: ParticipantType[]
    createdAt: string
    updatedAt: string
    __v: number
}

export type ParticipantType = {
    name: string
    email: string
}

export type SuccessResponseType = {
    message: string
}
