import {Navigate} from 'react-router-dom'
import {ReactElement, ReactNode} from 'react'
import {PATH} from 'enums/paths';
import {loadValue} from 'utils/localstorage';
import {LOCALSTORAGE_KEYS} from 'enums/localstorageKeys';

export const LoginNavigate = ({children}: {children: ReactNode}): ReactElement => {
    const isLoggedIn = !!loadValue(LOCALSTORAGE_KEYS.ACCESS_TOKEN)

    if (!isLoggedIn) return <Navigate to={PATH.LOGIN}/>

    return <>{children}</>
}
