import {Route, Routes, Navigate} from 'react-router-dom'
import {PATH} from 'enums/paths';
import {Login} from 'pages/Login/Login';
import {LoginNavigate} from 'hoc/LoginNavigate';
import {WebinarsList} from 'pages/WebinarsList/WebinarsList';

export const AllRoutes = () => (
    <Routes>
        <Route path="/" element={<Navigate to={PATH.WEBINARS}/>}/>
        <Route path={PATH.WEBINARS} element={<LoginNavigate><WebinarsList/></LoginNavigate>}/>
        <Route path={PATH.LOGIN} element={<Login/>}/>
    </Routes>
)
