import {ParticipantType} from 'api/webinars-api';
import styled from 'styled-components';

type PropsType = {
    index: number
    participant: ParticipantType
}

export const Participant = (props: PropsType) => {
    const {index, participant: {name, email}} = props

    return (
        <ParticipantBlock>
            <NumInfo>{index}</NumInfo>
            <ParticipantInfo>
                <Info>{name}</Info>
                <Info>{email}</Info>
            </ParticipantInfo>
        </ParticipantBlock>
    )
}

const ParticipantBlock = styled.div`
  display: flex;
  align-items: center;
  border: solid 1px #99cffc;
  padding: 10px;
`

const ParticipantInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 400px;
`

const NumInfo = styled.div`
  width: 24px;
  text-align: center;
`

const Info = styled.div`
  margin: 0 10px 0 20px;
`
