export const enum TEXT_CONSTANTS {
    SUCCESS_COPY = 'Регистрационная ссылка успешно скопирована в буфер обмена!',
    SUCCESS_SEND = 'Файл успешно отправлен на почту!',
    ADD_MODAL_TEXT = 'Добавление вебинара',
    EDIT_MODAL_TEXT = 'Редактирование вебинара',
    DELETE_MODAL_TEXT = 'Вы действительно хотите удалить вебинар?',
    SEND_FILE_MODAL_TEXT = 'Выслать данные об участниках вебинара на почту?',
    PARTICIPANTS_MODAL_TEXT = 'Список участников вебинара',
    AUTHORIZATION_ERROR = 'Ошибка авторизации!',
}
