import {useContext} from 'react';
import styled from 'styled-components';
import {AllRoutes} from 'pages/App/AllRoutes';
import {Header} from 'components/Header/Header';
import {CustomizedSnackbar} from 'components/CustomizedSnackbar/CustomizedSnackbar';
import {SnackbarTextContext} from 'provider/SnackbarTextProvider';

export const App = () => {
    const { text, setText } = useContext(SnackbarTextContext)

    return (
        <MainBlock>
            <CustomizedSnackbar open={!!text} setNotOpen={setText} text={text}/>
            <Header/>
            <Content><AllRoutes/></Content>
        </MainBlock>
    )
}

const MainBlock = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: linear-gradient(to bottom right, #e5f3fc, #bbd6f5);
  align-items: center;
`

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;

  @media (max-width: 814px) {
    width: 100%;
  }
`
