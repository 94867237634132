import {useContext} from 'react';
import {Navigate, useNavigate} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {PATH} from 'enums/paths';
import styled from 'styled-components';
import {TextField} from '@mui/material';
import {authAPI} from 'api/login-api';
import {PasswordFormControl} from 'components/PasswordFormControl/PasswordFormControl';
import {loadValue} from 'utils/localstorage';
import {LOCALSTORAGE_KEYS} from 'enums/localstorageKeys';
import {CustomButton} from 'components/CustomButton/CustomButton';
import {TEXT_CONSTANTS} from 'enums/textConstants';
import {SnackbarTextContext} from 'provider/SnackbarTextProvider';

export type FieldsType = {
    login: string
    password: string
}

export const Login = () => {
    const {register, handleSubmit} = useForm<FieldsType>();

    const navigate = useNavigate()

    const { setText } = useContext(SnackbarTextContext)

    const isLoggedIn = !!loadValue(LOCALSTORAGE_KEYS.ACCESS_TOKEN)

    const onSubmit = async (data: FieldsType) => {
        try {
            await authAPI.login(data)
            navigate(PATH.WEBINARS)
        } catch (e) {
            setText(TEXT_CONSTANTS.AUTHORIZATION_ERROR)
        }
    }

    if (isLoggedIn) return <Navigate to={PATH.WEBINARS}/>

    return (
        <LoginBlock>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Title>Sign In</Title>
                <Block>
                    <TextField {...register('login')}
                               fullWidth
                               label={'login'}
                               required/>
                </Block>
                <Block>
                    <PasswordFormControl register={register}/>
                </Block>
                <Block>
                    <CustomButton name={'LOG IN'} type={'submit'}/>
                </Block>
            </form>
        </LoginBlock>
    )
}

const LoginBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 350px;
  height: 500px;
  background: white;
  border-radius: 7px;
  background: linear-gradient(to bottom right, #e7f4fd, #cde9fa);
  box-shadow: 0 3px 7px 1px #83c0f1;
`

const Title = styled.h3`
  text-align: center;
`

const Block = styled.div`
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
`
