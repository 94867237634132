export const loadValue = (key: string): string | undefined => {
    try {
        const resultAsString = localStorage.getItem(key)
        if (resultAsString) return JSON.parse(resultAsString)
    } catch (err) {
        return undefined
    }
}

export const saveValue = (key: string, value: string): void => {
    try {
        localStorage.setItem(key, JSON.stringify(value))
    } catch {}
}

export const deleteValue = (key: string): void => {
    try {
        localStorage.removeItem(key)
    } catch {}
}
