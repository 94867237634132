import {useMutation, UseMutationResult} from 'react-query';
import {queryClient} from 'index';
import {CreateWebinarType, webinarsAPI, WebinarType} from 'api/webinars-api';
import {QUERY_KEYS} from 'enums/queryKeys';

export const useCreateWebinar = (): UseMutationResult<WebinarType, unknown, CreateWebinarType> =>
    useMutation((newWebinar: CreateWebinarType) => webinarsAPI.createWebinar(newWebinar), {
        onSuccess: (newWebinar) => {
            const accounts = queryClient.getQueryData<WebinarType[]>(QUERY_KEYS.WEBINARS_LIST)
            if (accounts) queryClient.setQueriesData(QUERY_KEYS.WEBINARS_LIST, [...accounts, newWebinar]);
        },
        meta: {
            error: 'Ошибка при создании вебинара'
        }
    })
