import {Modal} from 'components/Modals/Modal/Modal';
import {EditingForm, FormFieldsType} from 'components/EditingForm/EditingForm';
import {useCreateWebinar} from 'hooks/useCreateWebinar';
import {TEXT_CONSTANTS} from 'enums/textConstants';

type PropsType = {
    isOpen: boolean
    onClickClose: () => void
}

export const AddWebinarModal = (props: PropsType) => {
    const {isOpen, onClickClose} = props

    const { mutate: createWebinar } = useCreateWebinar()

    const onSubmit = (newWebinarData: FormFieldsType) => {
        createWebinar({...newWebinarData, topic: Number(newWebinarData.topic)})
        onClickClose()
    }

    return (
        <Modal isOpen={isOpen}
               onClickClose={onClickClose}
               title={TEXT_CONSTANTS.ADD_MODAL_TEXT}>
            <EditingForm onSubmit={onSubmit} onClickClose={onClickClose}/>
        </Modal>
    )
}
