import {ChangeEvent} from "react";
import { UseFormSetValue} from 'react-hook-form';
import TextField from '@mui/material/TextField';
import {FieldBlock} from 'components/FormField/FormField';
import {FormFieldsType} from 'components/EditingForm/EditingForm';

type PropsType = {
    name: keyof FormFieldsType
    value: string
    setValue: UseFormSetValue<FormFieldsType>
}

export const DateTimePicker = (props: PropsType) => {
    const {name, setValue, value} = props

    const handleDateChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValue(name,  new Date(e.target.value).toISOString())
    };

    // Форматируем дату для отображения в инпуте
    const formatDateForInput = (dateString: string) => {
        const date = new Date(dateString)
        const tzOffset = date.getTimezoneOffset() * 60000
        const localISOTime = new Date(date.getTime() - tzOffset).toISOString().slice(0, -1)
        return localISOTime.slice(0, 16)
    };

    const formattedDate = value ? formatDateForInput(value) : '';

    return (
        <FieldBlock>
            <TextField fullWidth
                       id="datetime-local"
                       type="datetime-local"
                       label={name}
                       value={formattedDate}
                       onChange={handleDateChange}
                       required
                       InputLabelProps={{shrink: true}}/>
        </FieldBlock>
    )
}
