import {z} from 'zod'

import {EnvironmentsTypes, appSettings} from './settings/app-settings'

const ApiSettingsSchema = z
    .object({
        REACT_APP_WEBINARS_SERVICE_URL: z.string().url(),
    })
    .strict()

const NodeEnvSchema = z.union([
    z.literal(EnvironmentsTypes.PRODUCTION),
    z.literal(EnvironmentsTypes.DEVELOPMENT),
    z.literal(EnvironmentsTypes.STAGING),
])

const EnvironmentSettingsSchema = z
    .object({
        env: NodeEnvSchema,
        getEnv: NodeEnvSchema,
        isDevelopment: z.boolean(),
        isProduction: z.boolean(),
        isStaging: z.boolean(),
    })
    .strict()

const AppSettingsSchema = z
    .object({
        env: EnvironmentSettingsSchema,
        api: ApiSettingsSchema,
    })
    .strict()

export const zodValidation = () => {
    try {
        AppSettingsSchema.parse(appSettings)
    } catch (err) {
        console.error('Ошибка конфигурации: ', err)
        throw err
    }
}

zodValidation()
