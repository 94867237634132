import {QueryClient, useMutation, UseMutationResult} from 'react-query';
import {QUERY_KEYS} from 'enums/queryKeys';
import {queryClient} from 'index';
import {webinarsAPI, WebinarType} from 'api/webinars-api';

export const useChangeWebinar = (): UseMutationResult<WebinarType, unknown, WebinarType, QueryClient> =>
    useMutation<WebinarType, unknown, WebinarType, typeof queryClient>(
        (webinar) => webinarsAPI.updateWebinar(webinar), {
            onSuccess: (updatedWebinar, variables) => {
                queryClient.setQueriesData([QUERY_KEYS.WEBINAR, updatedWebinar._id], updatedWebinar)
                const webinars = queryClient.getQueryData<WebinarType[]>(QUERY_KEYS.WEBINARS_LIST)
                if (webinars) queryClient.setQueriesData(
                    QUERY_KEYS.WEBINARS_LIST,
                    webinars.map((web) => (web._id === variables._id ? updatedWebinar : web)))
            },
            meta: {
                error: 'Ошибка при изменении вебинара'
            }
        }
    )
