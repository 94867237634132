import {Modal} from 'components/Modals/Modal/Modal';
import {ButtonsBlock} from 'components/EditingForm/EditingForm';
import {useDeleteWebinar} from 'hooks/useDeleteWebinar';
import {CustomButton} from 'components/CustomButton/CustomButton';
import {TEXT_CONSTANTS} from 'enums/textConstants';

type PropsType = {
    isOpen: boolean
    onClickClose: () => void
    webinarId: string
}

export const DeleteWebinarModal = (props: PropsType) => {
    const {isOpen, onClickClose, webinarId} = props

    const {mutate: deleteWebinar} = useDeleteWebinar(webinarId);

    const onSubmit = () => {
        deleteWebinar(webinarId)
        onClickClose()
    }

    return (
        <Modal isOpen={isOpen}
               onClickClose={onClickClose}
               title={TEXT_CONSTANTS.DELETE_MODAL_TEXT}>
            <ButtonsBlock>
                <CustomButton name={'CANCEL'} onClick={onClickClose}/>
                <CustomButton name={'DELETE'} onClick={onSubmit} color={'error'}/>
            </ButtonsBlock>
        </Modal>
    )
}
