import ReactDOM from 'react-dom/client';
import 'index.css';
import {App} from 'pages/App/App';
import reportWebVitals from 'reportWebVitals';
import {QueryClient, QueryClientProvider} from 'react-query';
import {BrowserRouter} from 'react-router-dom';
import {SnackbarTextProvider} from 'provider/SnackbarTextProvider';
import {zodValidation} from 'zod-validation';

export const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

zodValidation()

root.render(
    <QueryClientProvider client={queryClient}>
        <BrowserRouter>
            <SnackbarTextProvider>
                <App/>
            </SnackbarTextProvider>
        </BrowserRouter>
    </QueryClientProvider>
)

reportWebVitals()
