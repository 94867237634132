import {useCallback} from 'react';
import {authAPI} from 'api/login-api';
import styled from 'styled-components';
import {useNavigate} from 'react-router-dom';
import {PATH} from 'enums/paths';
import {loadValue} from 'utils/localstorage';
import {LOCALSTORAGE_KEYS} from 'enums/localstorageKeys';
import {ActionButton} from 'components/ActionButton/ActionButton';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutTwoTone';

export const Header = () => {
    const navigate = useNavigate()

    const isLoggedIn = !!loadValue(LOCALSTORAGE_KEYS.ACCESS_TOKEN)

    const logout = useCallback(() => {
        authAPI.logout()
        navigate(PATH.LOGIN)
    }, [])

    return (
        <HeaderContainer>
            <HeaderBlock>
                <SideElement/>
                <Title>Webinar Admin</Title>
                <SideElement>
                    {isLoggedIn && <ActionButton onClick={logout}
                                                 Icon={LogoutOutlinedIcon}
                                                 fontSize={'large'}/>}
                </SideElement>
            </HeaderBlock>
        </HeaderContainer>
    )
}

const Title = styled.div`
  margin: 14px 0 20px 0;
`

const HeaderContainer = styled.div`
  position: sticky;
  top: 0;
  width: 100vw;
  margin-bottom: 20px;
  background-color: #383838;
  box-shadow: 0 0 13px 1px #028eff;
  z-index: 1;
  font-size: 45px;
  line-height: 1.2;
  font-weight: 800;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`

const HeaderBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;

  @media (max-width: 814px) {
    width: 100%;
  }
`

/**
 * SideElement для выравнивания элементов
 * */
const SideElement = styled.div`
  width: 70px;
`
