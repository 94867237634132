import {useMutation, UseMutationResult} from 'react-query';
import {SuccessResponseType, webinarsAPI} from 'api/webinars-api';
import {AxiosResponse} from 'axios';

export const useFileSendingByWebinarId = (turnOnSendSnackbar: () => void): UseMutationResult<AxiosResponse<SuccessResponseType>, unknown, string> =>
    useMutation((webinarId: string) => webinarsAPI.getCSVFileOnEmail(webinarId), {
        onSuccess: () => turnOnSendSnackbar(),
        meta: {
            error: 'Ошибка при отправке файла'
        }
    })
