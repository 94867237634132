import {Modal} from 'components/Modals/Modal/Modal';
import {WebinarType} from 'api/webinars-api';
import {EditingForm, FormFieldsType} from 'components/EditingForm/EditingForm';
import {useChangeWebinar} from 'hooks/useChangeWebinar';
import {TEXT_CONSTANTS} from 'enums/textConstants';

type PropsType = {
    isOpen: boolean
    onClickClose: () => void
    webinar: WebinarType
}

export const EditWebinarModal = (props: PropsType) => {
    const {isOpen, onClickClose, webinar} = props

    const {mutate: changeWebinar} = useChangeWebinar()

    const onSubmit = (data: FormFieldsType) => {
        changeWebinar({...webinar, ...data, topic: Number(data.topic)})
        onClickClose()
    }

    return (
        <Modal isOpen={isOpen} onClickClose={onClickClose}
               title={TEXT_CONSTANTS.EDIT_MODAL_TEXT}>
            <EditingForm onSubmit={onSubmit}
                         onClickClose={onClickClose}
                         defaultValues={{...webinar}}/>
        </Modal>
    )
}
