import {useQuery, UseQueryResult} from 'react-query';
import {webinarsAPI, WebinarType} from 'api/webinars-api';
import {QUERY_KEYS} from 'enums/queryKeys';

export const useWebinars = (): UseQueryResult<WebinarType[]> =>
    useQuery<WebinarType[]>(
        [QUERY_KEYS.WEBINARS_LIST], () => webinarsAPI.getAllWebinars(),
        {
            meta: {
                error: 'Не получилось загрузить вебинары'
            }
        }
    )
