import {Modal} from 'components/Modals/Modal/Modal';
import {ButtonsBlock} from 'components/EditingForm/EditingForm';
import {useFileSendingByWebinarId} from 'hooks/useSendParticipantsList';
import {CustomButton} from 'components/CustomButton/CustomButton';
import {TEXT_CONSTANTS} from 'enums/textConstants';

type PropsType = {
    isOpen: boolean
    onClickClose: () => void
    webinarId: string
    turnOnSendSnackbar: () => void
}

export const SendFileModal = (props: PropsType) => {
    const {isOpen, onClickClose, webinarId, turnOnSendSnackbar} = props

    const {mutate: sendFile} = useFileSendingByWebinarId(turnOnSendSnackbar)

    const onSubmit = () => {
        sendFile(webinarId)
        onClickClose()
    }

    return (
        <Modal isOpen={isOpen} onClickClose={onClickClose}
               title={TEXT_CONSTANTS.SEND_FILE_MODAL_TEXT}>
            <ButtonsBlock>
                <CustomButton name={'CANCEL'} onClick={onClickClose} color={'error'}/>
                <CustomButton name={'SEND'} onClick={onSubmit}/>
            </ButtonsBlock>
        </Modal>
    )
}
