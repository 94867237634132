import {forwardRef, SyntheticEvent} from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, {AlertProps} from '@mui/material/Alert';

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
})

type PropsType = {
    open: boolean
    setNotOpen: (text: string) => void
    text: string
}

export const CustomizedSnackbar = (props: PropsType) => {
    const {open, setNotOpen, text} = props

    const handleClose = (event?: SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') return
        setNotOpen('')
    }

    return (
        <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="info">{text}</Alert>
            </Snackbar>
        </Stack>
    )
}
