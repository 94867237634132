import {useCallback, useState} from 'react';

type UseModalReturnType = [boolean, () => void, () => void]

export const useModal = (): UseModalReturnType => {
    const [isOpen, setIsOpen] = useState<boolean>(false)

    const openModal = useCallback(() => setIsOpen(true), [])

    const closeModal = useCallback(() => setIsOpen(false), [])

    return [isOpen, openModal, closeModal]
}
